/** @typedef {import('../../../@types/api-response').APIResponse} APIResponse */

/* eslint-disable camelcase */
import moment from 'moment';
import exodusInstance from 'lib/api/instances/exodus';

import { AWS_AVATAR_BUCKET, API_EXODUS_URL } from 'constants/api';
import {
  TAB_CATEGORIES,
  TAB_USER_CATEGORIES,
  STREAM_REPORT,
  STREAM_VERSION,
} from 'global/StreamWidget/constants';
import { cleanupStreamPayload } from 'utils/stream';
import { ChartTimeFrames } from 'features/company/constants.types';
import { APIResponse } from '../../../@types/api-response';

const requestExodus = exodusInstance.defaultInstance;
const requestExodusNoAlert = exodusInstance.noAlert;

// TODO: Change exodus url to stream url later on after every `/stream` path is migrated and tested
const STREAM_EXODUS_URL = `${API_EXODUS_URL}/stream`;
const STREAM_EXODUS_V3_URL = `${API_EXODUS_URL}/stream/v3`;
const STREAM_UPLOAD_TOKEN = `${STREAM_EXODUS_URL}/uploadtoken`;
const STREAM_UPLOAD_TOKEN_V2 = `${STREAM_EXODUS_URL}/upload-tokens`;

// Stockbit Stream -------------------------------------------------------------
/**
 * like stream post
 * @param {string} postid - post id
 * @returns {APIResponse}
 */
const likeStreamPost = (postid) =>
  requestExodusNoAlert.post(`${STREAM_EXODUS_URL}/like/${postid}`);

/**
 * unlike stream post
 * @param {string} postid - post id
 * @returns {APIResponse}
 */
const unlikeStreamPost = (postid) =>
  requestExodusNoAlert.post(`${STREAM_EXODUS_URL}/unlike/${postid}`);

/**
 *
 * @param {number=} version
 *
 * @param {Object} param
 * @param {number=} param.clean
 * @param {number=} param.beforelastpost
 * @param {string=} param.category
 * @param {number=} param.lastpost
 * @param {*=} param.keyword
 * @param {number=} param.limit
 * @param {string=} param.from
 * @param {string=} param.to
 * @param {string=} param.beforetimestamp
 * @returns {APIResponse}
 */
const getStream = (
  version = STREAM_VERSION.V3,
  {
    clean = 0,
    beforelastpost = 0,
    category = 'all',
    lastpost = 0,
    keyword,
    limit = 20,
    type = 'latest',
    from = '',
    to = '',
    beforetimestamp = null,
    date = moment(new Date()).format('YYYY-MM-DD'),
    page = 1,
    reporttype = '',
  },
) => {
  let params: any = {
    clean,
    beforelastpost,
    category,
    lastpost,
    keyword,
    limit,
    from,
    to,
    beforetimestamp,
  };

  let streamURL = STREAM_EXODUS_V3_URL;

  if (type === 'latest') {
    params.category = category;

    if (category === 'reports') {
      params.reporttype = reporttype;
    }
  } else if (type === 'trending' && !keyword) {
    streamURL = `${streamURL}/trending`;
    params = {
      date,
      page,
      limit,
    };
  } else {
    // For non Ideas Filter will have type 'ideas', use category as category
    // For Ideas with non 'All' filter, use type as category
    if (type === 'ideas') {
      params.category = category;
    } else if (type === 'trending' && keyword) {
      params.category = category;
    } else {
      params.category = type;
    }

    // Only report category has reporttype
    if (category === 'reports') {
      params.reporttype = reporttype;
    } else {
      params.filter = 'all';
    }
  }

  // Cleanup params with empty value
  params = cleanupStreamPayload(params);

  let body = {};

  if (type !== 'trending' || keyword) {
    body = {
      category: TAB_CATEGORIES[params.category],
      last_stream_id: lastpost,
      last_reply: beforetimestamp,
      keyword,
      from_date: from,
      to_date: to,
      limit,
    };

    if (params.reporttype) {
      // eslint-disable-next-line dot-notation
      body['report_type'] = STREAM_REPORT[params.reporttype];
    }
  } else if (type === 'trending' && !keyword) {
    body = {
      date,
      last_stream_id: lastpost,
      limit,
    };
  }

  body = cleanupStreamPayload(body);

  return requestExodus.post(streamURL, body);
};

/**
 * Get user stream data
 * @param {string} username
 *
 * @param {Object} param
 * @param {number} param.beforelastpost
 * @param {string} param.category
 * @param {number} param.lastpost
 * @param {*} param.keyword
 * @param {number} param.limit
 * @param {string} param.from
 * @param {string} param.to
 * @returns {APIResponse}
 */

const getUserStream = (
  username: string,
  {
    category = 'all',
    lastpost = 0,
    keyword,
    limit = 20,
    from = '',
    to = '',
  },
) => {
  const streamURL = `${STREAM_EXODUS_V3_URL}/user`;

  let body = {
    category: TAB_USER_CATEGORIES[category],
    last_stream_id: lastpost,
    keyword,
    from_date: from,
    to_date: to,
    limit,
  };

  body = cleanupStreamPayload(body);

  return requestExodus.post(`${streamURL}/${username}`, body);
};

/**
 * Get company stream data
 * @param {string} symbol
 *
 * @param {Object} param
 * @param {number} param.beforelastpost
 * @param {string} param.category
 * @param {number} param.lastpost
 * @param {*} param.keyword
 * @param {number} param.limit
 * @param {string} param.from
 * @param {string} param.to
 * @returns {APIResponse}
 */
const getSymbolStream = (
  symbol: string,
  {
    category = 'all',
    lastpost = 0,
    keyword,
    limit = 20,
    from = '',
    to = '',
    reporttype = '',
  },
) => {
  const streamURL = `${STREAM_EXODUS_V3_URL}/symbol`;

  let body = {};

  body = {
    category: TAB_CATEGORIES[category],
    last_stream_id: lastpost,
    keyword,
    from_date: from,
    to_date: to,
    limit,
  };

  if (reporttype) {
    // eslint-disable-next-line dot-notation
    body['report_type'] = STREAM_REPORT[reporttype];
  }

  body = cleanupStreamPayload(body);

  return requestExodusNoAlert.post(`${streamURL}/${symbol}`, body);
};

/**
 * Get stream post detail by post id
 * @param {string} symbol
 * @returns {APIResponse}
 */
const getGuestStream = (symbol) => requestExodusNoAlert.get(
    `${STREAM_EXODUS_URL}/non-login/symbol/${symbol}`,
  );

/**
 * Get company data for guest
 * @param {string} symbol
 * @returns {APIResponse}
 */
const getGuestCompany = (symbol) => requestExodusNoAlert.get(`${API_EXODUS_URL}/emitten/non-login/${symbol}/info`);

/**
 * Get company profile info
 * @param {string} symbol
 * @returns {APIResponse}
 */
const getGuestCompanyProfile = (symbol) => requestExodusNoAlert.get(
    `${API_EXODUS_URL}/partnership/emitten/${symbol}/profile`,
  );

/**
 * Get chart data for guest
 * @param {string} symbol
 * @returns {APIResponse}
 */
const getGuestChart = (symbol, timeframe: ChartTimeFrames = 'today') => requestExodusNoAlert.get(
    `${API_EXODUS_URL}/partnership/charts/${symbol}/daily?timeframe=${timeframe}`,
  );

/**
 * Get stream post detail by post id
 * @param {string|number} postId
 * @returns {APIResponse}
 */
const getStreamDetail = (postId: string | number): Promise<APIResponse> => {
  const streamURL = `${STREAM_EXODUS_V3_URL}/post/${postId}`;

  return requestExodusNoAlert.post(streamURL);
};

/**
 * Get stream post detail by post id
 * @param {string|number} postId
 * @returns {APIResponse}
 */
const getGuestStreamDetail = (postId) => requestExodusNoAlert.get(`${STREAM_EXODUS_URL}/non-login/post/${postId}`);

/**
 * getUploadToken
 * @param {string} bucket - bucket'sname
 * @returns {APIResponse}
 */
const getUploadToken = (bucket = 'avatar') =>
  requestExodusNoAlert.get(`${STREAM_UPLOAD_TOKEN}`, { params: { bucket } });

/**
 * postUploadAWS
 * @param {object} formData - file formdata
 * @returns {APIResponse}
 */
const postUploadAWS = (formData) =>
  fetch(AWS_AVATAR_BUCKET, {
    method: 'POST',
    cache: 'default',
    body: formData,
    mode: 'cors',
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    // TODO: alert error message

    return false;
  });

/**
 * API Compose new Stream
 * @param {Object} param
 * @param {string} param.content
 * @param {string} param.image
 * @param {Array} param.images
 * @param {Object} param.file
 * @param {number} param.target_price
 * @param {string} param.target_symbol
 * @param {number} param.target_duration
 * @param {Object=} param.polling
 * @param {string} param.commenter_type
 * @returns {APIResponse}
 */
const postStream = ({
  content,
  image,
  images = [],
  file = '',
  target_price,
  target_symbol,
  target_duration,
  polling,
  commenter_type,
}) => {
  const data: any = {
    content,
    image,
    images,
    file,
    target_price,
    target_symbol,
    target_duration,
    sharefacebook: 0,
    sharetwitter: 0,
    commenter_type,
  };

  if (polling) {
    data.polling_end = polling.polling_end;
    data.polling_options = polling.polling_options;
    data.polling_pin = polling.polling_pin;
  }

  return requestExodusNoAlert.post(`${STREAM_EXODUS_URL}/write`, data);
};

/**
 * API Update Post Stream
 * @param {Object} param
 * @param {string} param.content
 * @param {string} param.image
 * @param {Array} param.images
 * @param {Object} param.file
 * @param {number} param.target_price
 * @param {string} param.target_symbol
 * @param {number} param.target_duration
 * @param {Object=} param.polling
 * @param {number} param.postid
 * @param {string} param.commenter_type
 * @returns {APIResponse}
 */
const updatePostStream = ({
  content,
  image,
  images = [],
  file = '',
  target_price,
  target_symbol,
  target_duration,
  polling,
  postid,
  commenter_type,
}) => {
  const data: any = {
    content,
    image,
    images,
    file,
    target_price,
    target_symbol,
    target_duration,
    sharefacebook: 0,
    sharetwitter: 0,
    commenter_type,
  };

  if (polling) {
    data.polling_end = polling.polling_end;
    data.polling_options = polling.polling_options;
    data.polling_pin = polling.polling_pin;
  }

  return requestExodus.post(`${STREAM_EXODUS_URL}/update/${postid}`, data);
};

/**
 * API Share Post Stream
 * @param {Object} param
 * @param {string} param.content
 * @param {string} param.images
 * @param {Object} param.file
 * @param {number} param.postid
 * @param {number=} param.facebook
 * @param {number=} param.twitter
 * @param {number=} param.stockbit
 * @param {number=} param.commenter_type
 * @returns {APIResponse}
 */
const sharePostStream = ({
  content,
  images,
  file,
  postid,
  facebook = 0,
  twitter = 0,
  stockbit = 0,
  commenter_type,
}) => {
  const data = {
    postid,
    content,
    images,
    file,
    facebook,
    twitter,
    stockbit,
    commenter_type,
  };

  return requestExodusNoAlert.post(`${STREAM_EXODUS_URL}/share/${postid}`, {
    ...data,
  });
};

/**
 * Function to post vote to target price post
 * @param {number} targetPriceId - target price id
 * @param {0 | 1} agree - agree status 1: agree, 0: disagree
 * @returns {APIResponse}
 */
const postVoteTargetPrice = (targetPriceId, agree) => {
  const bodyFormData = new URLSearchParams();
  bodyFormData.append('tp_id', targetPriceId);
  bodyFormData.append('agree', agree);
  return requestExodusNoAlert.post(
    `${STREAM_EXODUS_URL}/addvote/${targetPriceId}`,
    bodyFormData,
  );
};

/**
 * Follow Post
 * @param {number|string} postid
 * @returns {APIResponse}
 */
const followPost = (postid) =>
  requestExodusNoAlert.post(`${STREAM_EXODUS_URL}/follow/${postid}`);

/**
 * UnFollow Post
 * @param {number|string} postid
 * @returns {APIResponse}
 */
const unfollowPost = (postid) =>
  requestExodusNoAlert.post(`${STREAM_EXODUS_URL}/unfollow/${postid}`);

/**
 * Save Post
 * @param {number|string} postid
 * @returns {APIResponse}
 */
const savePost = (postid) =>
  requestExodus.post(`${STREAM_EXODUS_URL}/save/${postid}`);

/**
 * UnSave Post
 * @param {number|string} postid
 * @returns {APIResponse}
 */
const unsavePost = (postid) =>
  requestExodusNoAlert.post(`${STREAM_EXODUS_URL}/unsave/${postid}`);

/**
 *  Get Announcement Report
 * @param {number|string} postid
 * @returns {APIResponse}
 */
const getAnnouncementReports = (postid) =>
  requestExodusNoAlert.get(`${STREAM_EXODUS_URL}/announcement/${postid}`);

/**
 * Post Report Stream
 * @param {number|string} postid
 * @param {string} type
 * @param {string} message
 * @returns {APIResponse}
 */
const postReportStream = (postid, type, message) => {
  const form = new URLSearchParams();
  form.append('type', type);
  form.append('message', message);
  return requestExodus.post(`${STREAM_EXODUS_URL}/report/${postid}`, form);
};

/**
 * Delete Post Stream
 * @param {number|string} postid
 * @param {string} reason
 * @param {boolean} sendEmail
 * @returns {APIResponse}
 */
const deletePostStream = (postid, reason, sendEmail = false) => {
  const data = {
    reason,
    send_email: sendEmail,
  };

  return requestExodus.post(`${STREAM_EXODUS_URL}/v2/delete/${postid}`, data);
};

/**
 * Delete Own Post Stream
 * @param {number|string} postid
 * @returns {APIResponse}
 */
const deleteOwnPostStream = (postid) =>
  requestExodus.delete(`${STREAM_EXODUS_URL}/own/${postid}`);

/**
 * Delete Own Post Stream
 * @param {number|string} postid
 * @returns {APIResponse}
 */
const deleteOwnPostComment = (postid) =>
  requestExodus.delete(`${STREAM_EXODUS_URL}/own/comment/${postid}`);

/**
 * Delete Multiple Post Stream
 * @param {number|string} userid
 * @param {string} time
 * @param {string} reason
 * @param {bool} sendEmail
 * @returns {APIResponse}
 */
const deleteMultiplePostStream = (userid, time, reason, sendEmail = false) => {
  const data = {
    user_id: userid,
    period: time,
    send_email: sendEmail,
    reason,
  };

  return requestExodus.post(`${STREAM_EXODUS_URL}/delete/multiple`, data);
};

/**
 * Pin Post
 * @param {number|string} postid
 * @param {string} symbol
 * @returns {APIResponse}
 */
const pinPost = (postid, symbol) => {
  const data = new URLSearchParams();
  data.append('postid', postid);

  if (symbol) data.append('symbol', symbol);

  return requestExodus.post(`${STREAM_EXODUS_URL}/pin?${data.toString()}`);
};

/**
 * UnPin Post
 * @param {number|string} postid
 * @param {string} symbol
 * @returns {APIResponse}
 */
const unpinPost = (postid, symbol) => {
  const data = new URLSearchParams();
  data.append('postid', postid);

  if (symbol) data.append('symbol', symbol);

  return requestExodus.post(`${STREAM_EXODUS_URL}/unpin?${data.toString()}`);
};

/**
 * Get user pinned post
 * @param {string} username
 * @returns {APIResponse}
 */
const getPinnedPost = (username: string): Promise<APIResponse> =>
  requestExodusNoAlert.post(`${STREAM_EXODUS_V3_URL}/user/${username}/pinned`);

/**
 * Get company pinned post
 * @param {string} symbol
 * @returns {APIResponse}
 */
const getCompanyPinnedPost = (symbol: string): Promise<APIResponse> =>
  requestExodusNoAlert.get(`/stream/v3/symbol/${symbol}/pinned`);

/**
 * Get Stream Conversation
 * @param {number|string} postid
 * @returns {APIResponse}
 */
const getStreamConversation = ({ postId, parentId }) => {
  const streamURL = `${STREAM_EXODUS_V3_URL}/conversation`;

  let body = {
    stream_id: postId,
    limit: 20,
  };

  const parentID = parentId > 0 ? parentId : postId;

  body = cleanupStreamPayload(body);
  return requestExodusNoAlert.post(`${streamURL}/${parentID}`, body);
};

/**
 * Get Prev Stream Conversation
 * @param {number|string} postid
 * @returns {APIResponse}
 */
const getPrevStreamConversation = (postid: number | string): Promise<APIResponse> => {
  const streamURL = `${STREAM_EXODUS_V3_URL}/conversation`;

  let body = {
    stream_id: postid,
    is_previous: true,
    limit: 20,
  };

  body = cleanupStreamPayload(body);
  return requestExodusNoAlert.post(`${streamURL}/comments`, body);
};

/**
 * Post Reply Stream
 * @param {Object} param
 * @param {string=} param.content
 * @param {string=} param.image
 * @param {string=} param.images
 * @param {Object=} param.file
 * @param {number=} param.postid
 * @param {number} param.replyto
 * @param {number|string} param.parent
 * @returns {APIResponse}
 */
const postReplyStream = ({
  content = '',
  image = null,
  images = [],
  file = null,
  replyto,
  parent,
}) => {
  const data: any = {
    content,
    image,
    images,
    file,
    sharefacebook: 0,
    sharetwitter: 0,
  };

  if (replyto) {
    data.replyto = replyto;
  }

  return requestExodusNoAlert.post(`${STREAM_EXODUS_URL}/reply/${parent}`, data);
};

/**
 * Untag all post topics inside stream post
 * @param {number} postId - Stream post ID
 * @returns {APIResponse}
 */
const untagPostTopic = (postId) =>
  requestExodus.post(`${STREAM_EXODUS_URL}/topic/untag/${postId}`);

/**
 * Get stream likers
 * @param {string} username
 * @param {string | number} postid
 * @param {number} page
 * @return {APIResponse}
 */
const getStreamLikers = (username, postid, page = 1) =>
  requestExodusNoAlert.get(`${STREAM_EXODUS_URL}/likers/${postid}`, {
    params: { username, page },
  });

/**
 * getUploadToken version 2
 * @param {string} filename
 * @returns {APIResponse}
 */
const getUploadTokenV2 = (params) =>
  requestExodusNoAlert.get(`${STREAM_UPLOAD_TOKEN_V2}`, { params });

/**
 * Change Commenter Type of Post
 * @param {number} type - Stream post Commenter Type
 * @param {string | number} postid
 * @returns {APIResponse}
 */
const changeCommenterType = (type, postid) =>
  requestExodus.put(`${STREAM_EXODUS_URL}/commenter-type/${postid}`, {
    commenter_type: type,
  });

/**
 * Post Polling Vote
 * @param {number} pollingId - Stream post ID
 * @param {number} option - selected option
 * @returns {APIResponse}
 */
const postPollingVote = (pollingId, option) =>
  requestExodus.post(`${STREAM_EXODUS_URL}/polling/vote/${pollingId}`, {
    option,
  });

const getGuestUserStream = (username: string) => requestExodusNoAlert.get(
  `${API_EXODUS_URL}/stream/non-login/user/${username}`,
);

export default {
  likeStreamPost,
  unlikeStreamPost,
  getStream,
  getUserStream,
  getSymbolStream,
  getStreamDetail,
  getUploadToken,
  postUploadAWS,
  postStream,
  updatePostStream,
  sharePostStream,
  postVoteTargetPrice,
  followPost,
  unfollowPost,
  savePost,
  unsavePost,
  getAnnouncementReports,
  postReportStream,
  deletePostStream,
  deleteOwnPostStream,
  deleteOwnPostComment,
  deleteMultiplePostStream,
  pinPost,
  unpinPost,
  getPinnedPost,
  getCompanyPinnedPost,

  getStreamLikers,

  getStreamConversation,
  getPrevStreamConversation,
  postReplyStream,

  untagPostTopic,
  getUploadTokenV2,
  getGuestStreamDetail,
  getGuestStream,
  getGuestCompany,
  getGuestCompanyProfile,
  changeCommenterType,
  getGuestChart,
  postPollingVote,

  getGuestUserStream,
};
