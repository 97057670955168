import { PROJECT_FOLDER } from 'constants/app';
import requestConfig from 'config/requestNext';

import { APIResponse } from '../../../@types/api-response';

const request = requestConfig();

interface EmailLoginParams {
  username: string;
  password: string;
  verificationToken: string;
}

export const emailLogin = ({
  username,
  password,
  verificationToken,
}: EmailLoginParams): Promise<APIResponse> => {
  if (PROJECT_FOLDER) {
    return request.get('/api/login/email', {
      params: {
        username,
        password,
        verificationToken,
      },
    });
  }
  return request.post('/api/login/email', {
    username,
    password,
    verificationToken,
  });
};

interface FbLoginParams {
  id: string;
  key: string;
}

export const fbLogin = ({ id, key }: FbLoginParams): Promise<APIResponse> => {
  if (PROJECT_FOLDER) {
    return request.get('/api/login/facebook', {
      params: {
        id,
        key,
      },
    });
  }
  return request.post('/api/login/facebook', {
    id,
    key,
  });
};

interface GoogleLoginParams {
  id: string;
  key: string;
}

export const googleLogin = ({
  id,
  key,
}: GoogleLoginParams): Promise<APIResponse> => {
  if (PROJECT_FOLDER) {
    return request.get('/api/login/google', {
      params: {
        id,
        key,
      },
    });
  }
  return request.post('/api/login/google', {
    id,
    key,
  });
};

interface VerifyOTPParams {
  key?: string;
  otp: string;
}

export const verifyOTP = ({
  key,
  otp,
}: VerifyOTPParams): Promise<APIResponse> => {
  if (PROJECT_FOLDER) {
    if (key) {
      return request.get('/api/register/verify-otp', {
        params: {
          key,
          otp,
        },
      });
    }
    return request.get('/api/register/verify-otp', {
      params: {
        otp,
      },
    });
  }
  return request.post('/api/register/verify-otp', {
    key,
    otp,
  });
};

export const logout = (): Promise<APIResponse> => {
  if (PROJECT_FOLDER) {
    return request.get('/api/logout');
  }
  return request.post('/api/logout');
};
