/* eslint-disable wrap-iife */
import type { PropsWithChildren } from 'react';
/* eslint-disable no-undef */
import { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useRouter } from 'next/router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// store
import { useDispatch, useSelector } from 'react-redux';
import Google from 'global/Scripts/google';
import { wrapper } from 'lib/store';
import { isLoggedInSelector } from 'lib/entities/credentials';

// configs
import theme from 'config/theme';

// styles
import GlobalStyles from 'global/GlobalStyles';

// scripts
import TikTokPixel from 'global/Scripts/TikTokPixel';

// components
import SupportButton from 'global/SupportButton';
import { DatafeedProvider } from 'global/Datafeed';
import SecurityProvider from 'global/Securities/websocket/SecuritiesProvider';

// hooks
import useClevertap from 'hooks/useClevertap';
import useTradingAccount from 'hooks/useTradingAccount';
import useWebSocketSocialV2 from 'hooks/useWebSocketSocialV2';

import { sentrySetUser } from 'utils/logger';

import If from 'global/ErrorHandlers/If';

import Primus from 'global/Scripts/primus';
import Firebase from 'global/Scripts/firebase';
import mainSlice from 'global/MainLayout/slice';
import useCrisp from 'hooks/useCrisp';
import useSharedWindowData from 'hooks/useSharedWindowData';
import useFliptStore from 'lib/flipt/store/useFliptStore';
import auth from 'utils/auth';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

// eslint-disable-next-line @typescript-eslint/ban-types
type MainAppProps = PropsWithChildren<{}>;

function MainApp({ children }: MainAppProps) {
  const router = useRouter();
  const routerPath = router.asPath;
  const dispatch = useDispatch();
  useSharedWindowData(); // Used for Nx module federation
  useCrisp();
  useClevertap();
  const isLoggedIn = useSelector(isLoggedInSelector) as boolean;
  const setFliptUserId = useFliptStore((s) => s.setUserId);
  const au = auth.getUserAccess();
  const { tradingType } = useTradingAccount();
  useWebSocketSocialV2({ isLoggedIn });

  const isSecuritiesStaticPage = router.asPath.includes('/securities/static');
  const hideSupportButton = isSecuritiesStaticPage;
  const isFundachartPage = false;
  const renderPrimusScript = !isFundachartPage;
  const isScriptShouldShow = [isFundachartPage].every((item) => !item);

  // Reset minimized header when route changes
  useEffect(() => {
    // @ts-ignore
    dispatch(mainSlice.actions.setHeaderMinimized(false));
  }, [routerPath]);

  useEffect(() => {
    sentrySetUser(isLoggedIn);
  }, [isLoggedIn]);

  useEffect(() => {
    setFliptUserId(au?.id);
  }, [au?.id]);

  return (
    <>
      <If condition={isScriptShouldShow}>
        <Google />
        <TikTokPixel />
      </If>
      <If condition={!hideSupportButton}>
        <SupportButton isLoggedIn={isLoggedIn} />
      </If>
      <If condition={renderPrimusScript}>
        <Primus />
      </If>
      <If condition={isScriptShouldShow}>
        <Firebase />
      </If>
      <QueryClientProvider client={queryClient}>
        <DatafeedProvider isLoggedIn={isLoggedIn}>
          <SecurityProvider isLoggedIn={isLoggedIn && !!tradingType}>
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              {children}
            </ThemeProvider>
          </SecurityProvider>
        </DatafeedProvider>
      </QueryClientProvider>
    </>
  );
}

export default wrapper.withRedux(MainApp);
